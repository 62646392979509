// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import { Component } from "vue-property-decorator";
import api from "@/services/api";
import baseUserContextComponent from "@/components/baseUserContextComponent";
import permessi from "@/config/permessi";

@Component({})
export default class customTable extends baseUserContextComponent {
	tabPemessi(tab: string): boolean {
		if (tab == "gruppiStati") return this.hasPermesso(permessi.BK.Amministrazione.GRUPPISTATI);
		else if (tab == "stati") return this.hasPermesso(permessi.BK.Amministrazione.STATI);
		else if (tab == "codiciPagamento") return this.hasPermesso(permessi.BK.Amministrazione.CODICI_PAGAMENTO);
		else if (tab == "tipiDistributore") return this.hasPermesso(permessi.BK.Amministrazione.TIPI_DISTRIBUTORE);
		else if (tab == "tipiExtraInfo") return this.hasPermesso(permessi.BK.Amministrazione.EXTRA_INFO);
		else if (tab == "nazionalita") return this.hasPermesso(permessi.BK.Amministrazione.NAZIONALITA);
		else if (tab == "gruppiNazionalita") return this.hasPermesso(permessi.BK.Amministrazione.GRUPPINAZIONALITA);
		else if (tab == "pacchettiCategorie") return this.hasPermesso(permessi.BK.Amministrazione.PACCHETTI_CATEGORIE);
		else if (tab == "commissioniBeneficiari") return this.hasPermesso(permessi.BK.Amministrazione.COMMISIONI_BENEFICARI);
		else if (tab == "contraenti") return this.hasPermesso(permessi.BK.Amministrazione.CONTRAENTI);
		else if (tab == "impostazioni") return this.hasPermesso(permessi.BK.Amministrazione.IMPOSTAZIONI);
		else if (tab == "popupCarrello") return this.hasPermesso(permessi.BK.Amministrazione.POPUP_CARRELLO);
		else if (tab == "tipologieProdotti") return this.hasPermesso(permessi.BK.Amministrazione.TIPOLOGIE_PRODOTTI);
		else if (tab == "eta") return this.hasPermesso(permessi.BK.Amministrazione.ETA);
		else if (tab == "parametri") return this.hasPermesso(permessi.BK.Amministrazione.PARAMETRI);
		else if (tab == "informazioni") return this.hasPermesso(permessi.BK.Amministrazione.INFORMAZIONI);
		else if (tab == "agenzie") return this.hasPermesso(permessi.BK.Anagrafica.AGENZIE);

		else return false;
	}
}